import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LogRocket from 'logrocket';
LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);  // Use environment variable for LogRocket

const apiUrl = process.env.REACT_APP_API_BASE_URL;

function Auth() {
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(false); // State to manage authentication process
  const [authError, setAuthError] = useState(null); // State to manage authentication errors

  const fetchUserProfile = useCallback((accessToken) => {
    const url = "https://graph.microsoft.com/v1.0/me";
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        console.log('User Profile:', data);
        sessionStorage.setItem('userName', data.displayName);  // Assuming displayName holds the name
        setIsAuthenticating(false); // End the authentication process
        navigate('/'); // Navigate to home after fetching user profile
    })
    .catch(error => {
        console.error('Error fetching user profile:', error);
        setIsAuthenticating(false); // Reset the state if there's an error
        setAuthError('Failed to fetch user profile.');
    });
  }, [navigate]);

  const exchangeCodeForToken = useCallback((code) => {
    console.log("apiUrl: " + apiUrl);
    fetch(`${apiUrl}/AuthFunction?code=${code}`, {
      method: 'GET',
      headers: {
          'Accept': 'application/json'
      }
    }).then(response => {
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    }).then(data => {
      console.log('Token:', data.accessToken);
      sessionStorage.setItem('accessToken', data.accessToken);
      fetchUserProfile(data.accessToken);
    }).catch(error => {
      console.error('Error fetching token:', error);
      setIsAuthenticating(false); // Reset the state if there's an error
      setAuthError('Failed to exchange code for token.');
    });
  }, [fetchUserProfile]);

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');
    if (code) {
      setIsAuthenticating(true); // Start the authentication process
      exchangeCodeForToken(code);
      window.history.replaceState(null, '', window.location.pathname);  // Clear the code from URL
    }
  }, [exchangeCodeForToken]);

  const handleAuth = useCallback(() => {
    const tenantId = process.env.REACT_APP_TENANT_ID;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = encodeURIComponent(`${window.location.origin}/auth`);

    console.log("Hello, Using auth callback!");

    console.log('Tenant ID:', tenantId);
    console.log('Client ID:', clientId);
    console.log('API URL:', apiUrl);

    const scope = encodeURIComponent('User.Read');  

    const responseType = 'code';

    const authUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&response_mode=query`;

    setIsAuthenticating(true); // Start the authentication process
    setAuthError(null); // Clear any previous errors
    window.location.href = authUrl;
  }, []);

  return (
    <div>
      <h2>Please login with your Kynetec SSO credentials</h2>
      <h4>Note: You must be logged into the Kynetec VPN</h4>
      {authError && <p className="error">{authError}</p>} {/* Display error message if any */}
      <button onClick={handleAuth} disabled={isAuthenticating}>
        {isAuthenticating ? 'Authenticating...' : 'Log In'}
      </button>
    </div>
  );
}

export default Auth;
