import React from 'react';

function Header({ userName, handleLogout }) {
    return (
        <div className="header-bar">
            <div className="header-left">
                <h1>Data Quality Anomaly Viewer</h1>
            </div>
            <div className="header-right">
                <span>Welcome {userName || 'Guest'}</span>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
        </div>
    );
}

export default Header;
