import * as XLSX from 'xlsx';

export async function exportToExcel(data) {
    try {
        // Log the data to be exported
        // Ensure the data is an array and not empty
        if (!Array.isArray(data) || data.length === 0) {
            throw new Error('Invalid data format: data should be a non-empty array');
        }

        // Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "QueryResults");

        // Generate Excel file as binary string
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        return excelBuffer;
    } catch (error) {
        console.error('Error exporting to Excel:', error);
        throw error; // Re-throw the error to handle it in the calling function
    }
}