import React from 'react';

const apiUrl = process.env.REACT_APP_API_BASE_URL;


function QueryEditor({ isAuthenticated, accessToken, query, setQuery, onExecuteCustomQuery, selectedDatabase }) {
    // This function executes when the Execute Custom Query button is clicked
    const executeCustomQuery = () => {
        console.log("Executing custom query...");
        fetch(`${apiUrl}/ExecuteQuery`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query, database: selectedDatabase }) // Now uses selectedDatabase
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to execute query');
            }
            return response.json();
        })
        .then(data => {
            console.log('Query executed successfully:', data);
            if (onExecuteCustomQuery) {
                onExecuteCustomQuery(data); // If there is a handler, call it
            }
        })
        .catch(error => {
            console.error('Error executing query:', error);
        });
    };

    return (
        <div>
            <h2>Execute Custom SQL Query</h2>
            <textarea
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                rows="6"
                cols="70"
                placeholder="Type your SQL query here..."
                disabled={!isAuthenticated || !accessToken}
            />
            <button onClick={executeCustomQuery} disabled={!query || !isAuthenticated || !accessToken}>
                Execute Custom Query
            </button>
        </div>
    );
}

export default QueryEditor;
