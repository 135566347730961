import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { exportToExcel } from './utils/exportToExcel';  // Ensure this path is correct
import JSZip from 'jszip';  // Import JSZip library
const apiUrl = process.env.REACT_APP_API_BASE_URL;

function ResultsTable({ isAuthenticated, accessToken, queryResult, queryStatus, setExportStatus, selectedDatabase }) {
    const [failedExports, setFailedExports] = useState([]);
    const [completedExports, setCompletedExports] = useState(0);
    const [loadingMap, setLoadingMap] = useState({});
    const [exportBatchLoading, setExportBatchLoading] = useState(false);

    const formatDate = (timedatecol) => {
        return timedatecol.split('T')[0] // Assuming run_date is ISO string and removing time
    };
    
    // Function to get the first max five words from a sentence
    const getFirstFiveWords = (sentence) => {
        return sentence !== "" ? sentence.split(' ').slice(0, 5).join(' ') : "";
    };

    const handleExportSource = async (query, row, zip, index) => {
        if (!accessToken || !query) {
            console.error("Missing token or query");
            return;
        }
        if(!zip){
            setLoadingMap(prev => ({ ...prev, [`${index}_${row.rule_id}`]: true }));
        }
        
        const parseRunName = (runName) => {
            const details = {};
            runName.split(', ').forEach(part => {
                const [key, value] = part.split(':');
                details[key.trim()] = value.trim();
            });
            return details;
        };

        const runDetails = parseRunName(row.run_name);
        const fileName = [
            formatDate(row.run_date),
            runDetails['Country'],
            runDetails['Project'],
            row.field_name,
            row.rule_id,
            getFirstFiveWords(row.rule_description),
        ].filter(Boolean).join('-') + '.xlsx';
        
        setExportStatus(`Executing query for Rule ID: ${row.rule_id}...`);

        try {
            const response = await fetch(`${apiUrl}/ExecuteQuery`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query, database: selectedDatabase + "-Source" })
            });

            if (!response.ok) {
                throw new Error(`Failed to execute query for ID: ${row.rule_id}`);
            }

            const data = await response.json();

            const modifiedData = data.map(entry => {
                return { rule_id: row.rule_id, ...entry };
            });

            const excelBuffer = await exportToExcel(modifiedData);
            if (zip) {
                zip.file(fileName, excelBuffer);
                console.log(`File added to zip: ${fileName}`);
            } else {
                saveAs(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName);
            }

            setExportStatus(`Data for Rule ID: ${row.rule_id} has been downloaded to your device`);
        } catch (error) {
            console.error('Error executing query for export:', error);
            setFailedExports(prev => [...prev, [`${index}_${row.rule_id}`]]);
            setExportStatus(`Failed to export ID: ${row.rule_id}`);
        } finally {
            setLoadingMap(prev => ({ ...prev, [`${index}_${row.rule_id}`]: false }));
            setCompletedExports(prev => prev + 1);
        }
    };

    const handleBatchExport = async () => {
        const totalRecords = queryResult.length;
        if (window.confirm(`Are you sure you want to download all ${totalRecords} records to your computer?`)) {
            setFailedExports([]);
            setCompletedExports(0);
            setExportBatchLoading(true);

            const zip = new JSZip();

            await Promise.all(queryResult.map((row, index) => handleExportSource(row.result_query, row, zip, index)));

            const zipBlob = await zip.generateAsync({ type: 'blob' });
            setExportBatchLoading(false);
            console.log('Zip file generated successfully');
            const getCurrentDateFormatted =  new Date().toISOString().split('T')[0];
            saveAs(zipBlob, `Query Result_${getCurrentDateFormatted}.zip`);
        }
    };

    useEffect(() => {
        if (completedExports === queryResult.length && completedExports > 0) {
            setExportStatus(`Batch export completed: ${queryResult.length - failedExports.length} successful, ${failedExports.length} failed. Failed Queries for Rule IDs: ${failedExports.join(', ')}`);
        }
    }, [completedExports, failedExports, queryResult.length, setExportStatus]);

    const coulmnName = {
        result_query:"Export",
        run_date: "Run Date",
        run_name: "Run Name",
        field_name: "Field Name",
        rule_id: "Rule Id",
        rule_description: "Rule Description",
        number_of_discrepancies: "Anomalies"
    }

    const renderTable = () => {
        if (!Array.isArray(queryResult)) {
            return <p>{queryResult}</p>;
        }
        if (queryResult.length === 0) {
            return <p>No data to display</p>;
        }

        const columnOrder = Object.keys(coulmnName);
        const sortedQueryResult = [...queryResult].sort((a, b) => b.number_of_discrepancies - a.number_of_discrepancies);

        return (
            <>
                <div className="button-container">
                    <button onClick={handleBatchExport} disabled={exportBatchLoading}>
                        {exportBatchLoading ? 'Downliading Zip...' : 'Export Batch'}
                    </button>
                </div>
                <table className="results-table">
                    <thead>
                        <tr>
                            {columnOrder.map(column => (
                                <th key={column}>{coulmnName[column]}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedQueryResult.map((row, index) => (
                            <tr key={index}>
                                {columnOrder.map(column => (
                                    <td key={`${index}-${column}`} width={column === 'run_date' ? 80 : column === 'rule_id' ? 60 : 'auto'}>
                                        {column === 'run_date' ? formatDate(row[column]) :
                                            column === 'result_query' && row[column] ?
                                                <button onClick={() => handleExportSource(row.result_query, row, null, index )} disabled={loadingMap[`${index}_${row.rule_id}`]}>
                                                    {loadingMap[`${index}_${row.rule_id}`] ? 'download...' : 'Export'}
                                                </button> :
                                                (row[column] !== null && row[column] !== undefined ? row[column].toString() : '')}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    };

    return (
        <div>
            <p>{queryStatus}</p>  {/* Display the query status here */}
            <h3>Results:</h3>
            {renderTable()}
        </div>
    );
}

export default ResultsTable;
