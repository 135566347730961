import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './components/home';
import Auth from './components/auth';

function App() {
  // For validation purposes
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  console.log("Env apiUrl: " + apiUrl);
  console.log("Node environment: " + process.env.NODE_ENV);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
