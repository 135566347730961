import React from 'react';

function StatusDisplay({ statusMessage }) {
    return (
        <div className="status-display">
            {statusMessage}
        </div>
    );
}

export default StatusDisplay;
